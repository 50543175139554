import React, { useState,useEffect } from 'react';
import { Button, Table,Form } from 'react-bootstrap';


import { useParams } from 'react-router-dom';
const GiftVoucher=({userId}) =>{
  const initialFormData = {
    voucherSlno: '0',
    voucherDate: '',
    vso: '',
    client: '',
    clientname: '',
    voucherDescription: '',
    voucherValue: '',
    voucherPoints: '',
    managerid:userId
  };
    const [formData, setFormData] = useState(initialFormData);
      const [Staffs,setStaffs]=useState([]);
      const [Clients,setClients]=useState([]);
      const [BonusBalance,setBonusBalance]=useState();
      const handleInputChange = (e) => {
        const { name, value,text } = e.target;
        setFormData({ ...formData, [name]: value });
        if(name === 'vso') {
          getClientlist(value);
        }
        if(name === 'client') {
          getBalance(value);          
        }        
      };
      const handleValueChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, ['voucherPoints']: value/100,['voucherValue']: value });        
        /*if(name === 'voucherValue') {
          setFormData({ ...formData, ['voucherValue']: value });
        }*/
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
        const tempcid=formData.client;
        formData.clientname=Clients.find(client => client.client_id === tempcid).client_name;
        console.log('Form Data Submitted:', formData);
        // Add form submission logic here (e.g., API call)
        const response=await fetch('/api/addgiftvoucher',{
          method:'POST',
          headers:{
            'Content-Type':'application/json'
          },
          body:JSON.stringify(formData)
        });
        console.log(response);
        if(response.status===201){
          alert('Gift Voucher Added Successfully');
          setFormData(initialFormData);  
          document.getElementById("voucherValue").value = '';
        }
        else{
          alert('Error');
        }
      };
      const getStafflist  = async () =>{
        const response = await fetch(`/api/getjuniorstafflist/${userId}`);
        const data = await response.json();
        setStaffs(data);
    }
    const getClientlist  = async (vsoid) =>{
      const response = await fetch(`/api/getvsoclients/${vsoid}`);
      const data = await response.json();
      setClients(data);
    }
    const getBalance  = async (clientid) =>{
      const response = await fetch(`/api/bonusbalance/${clientid}`);
      const data = await response.json();
      setBonusBalance(data.closing_balance);
    }
    useEffect(()=>{
      getStafflist();
      //getClientlist();
    },[]);
      return (
        <div className="container mt-5">
          <h2 className="text-center mb-4">Gift Voucher</h2>
          <form className="row g-3" onSubmit={handleSubmit}>
            {/* Voucher Serial Number */}
            <div className="col-md-6">
              <label htmlFor="voucherSlno" className="form-label">
                Voucher Slno
              </label>
              <input
                type="text"
                className="form-control"
                id="voucherSlno"
                name="voucherSlno"
                placeholder="1"
                value={formData.voucherSlno}
                onChange={handleInputChange}
                required
              />
            </div>
    
            {/* Voucher Date */}
            <div className="col-md-6">
              <label htmlFor="voucherDate" className="form-label">
                Voucher Date
              </label>
              <input
                type="date"
                className="form-control"
                id="voucherDate"
                name="voucherDate"
                value={formData.voucherDate}
                onChange={handleInputChange}
                required
              />
            </div>
    
            {/* VSO */}
            <div className="col-md-6">
              <label htmlFor="vso" className="form-label">
                VSO
              </label>
              <select
                className="form-select"
                id="vso"
                name="vso"
                value={formData.vso}
                onChange={handleInputChange}
                required
              >
                <option value="">Select VSO</option>
                {Staffs.map((staff)=>(
                            <option key={staff.id} value={staff.id}>
                                {staff.fullname}
                            </option>
                        ))}
              </select>
            </div>
    
            {/* Client */}
            <div className="col-md-6">
              <label htmlFor="client" className="form-label">
                Client / Doctor
              </label>
              <select
                className="form-select"
                id="client"
                name="client"
                value={formData.client}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Client</option>
                {Clients.length > 0 ?(
                        Clients.map((client)=>(
                            <option key={client.client_id} value={client.client_id}>
                                {client.client_name}
                            </option>
                        ))
                        ):(null)}
              </select>
            </div>
    
            {/* Voucher Description */}
            <div className="col-md-12">
              <label htmlFor="voucherDescription" className="form-label">
                Voucher Description
              </label>
              <textarea
                className="form-control"
                id="voucherDescription"
                name="voucherDescription"
                rows="3"
                placeholder="Enter Voucher Description"
                value={formData.voucherDescription}
                onChange={handleInputChange}
                required
              ></textarea>
            </div>
    
            {/* Voucher Value */}
            <div className="col-md-6">
              <label htmlFor="voucherValue" className="form-label">
                Voucher Value
              </label>
              <input
                type="text"
                className="form-control"
                id="voucherValue"
                name="voucherValue"
                placeholder="0.00"
                onChange={handleValueChange}                                
                required
              />
            </div>
    
            {/* Voucher Points */}
            <div className="col-md-6">
              <label htmlFor="voucherPoints" className="form-label">
                Voucher Points
              </label>
              <input
                type="number"
                className="form-control"
                id="voucherPoints"
                name="voucherPoints"
                placeholder="0.00"
                value={formData.voucherPoints}
                required
              />
              <div class="text-danger">[Bonus Balance:{BonusBalance}]</div>
            </div>
    
            {/* Save Button */}
            <div className="col-md-12 text-center">
              <button type="submit" className="btn btn-primary px-4">
                Save
              </button>
            </div>
          </form>
        </div>
      );
}
export default GiftVoucher;