import React, { useState,useEffect } from 'react';
import { Table, Container, Alert, Dropdown, Button, Form, Col} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
const GiftVoucherList = ({userId}) => {
    const [Staffs,setStaffs]=useState([]);
    const [Clients,setClients]=useState([]);
    const [GiftVoucher,setGiftVoucher]=useState([]);
    const [FilteredVouchers,setFilteredVouchers]=useState([]);
    const [selectedClientId,setselectedClientId]=useState("");
    const [selectedStaffId,setselectedStaffId]=useState("");
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const navigate = useNavigate();
    const handleViewDetails = (voucherId) => {
        //navigate(`/visitdetails/${visitId}`);
    };
    const getStafflist  = async () =>{
        const response = await fetch(`/api/getjuniorstafflist/${userId}`);
        const data = await response.json();
        setStaffs(data);
    }
    const getClientlist  = async (vsoid) =>{
        const response = await fetch(`/api/getvsoclients/${vsoid}`);
        const data = await response.json();
        setClients(data);
    }
    const getGiftVouchers  = async (clientid) =>{
        const response = await fetch(`/api/giftvouchers/${clientid}`);
        const data = await response.json();
        setGiftVoucher(data);
        setFilteredVouchers(data);
    }
    const filterVoucherByDate = () => {
        if (!Array.isArray(GiftVoucher)) return; // Check if visitData is an array
        const filtered = GiftVoucher.filter(visit => {
          const visitDate = new Date(visit.vdate);
          const start = fromDate ? new Date(fromDate) : null;
          const end = toDate ? new Date(toDate) : null;
          return (!start || visitDate >= start) && (!end || visitDate <= end);
        });
        setFilteredVouchers(filtered);
      };
    useEffect(()=>{
        getStafflist();
        //getClientlist();
    },[]);
    useEffect(() => {
        filterVoucherByDate();
      }, [fromDate, toDate, GiftVoucher]);
    const handleStaffChange = (e) => {
        const { value } = e.target;        
        getClientlist(value);
        setselectedStaffId(value);
      };
    const handleClientChange = (e) => {
        const { value } = e.target;
        getGiftVouchers(value);
        setselectedClientId(value);
      };
    return(
        <Container>
            <h1>Gift Voucher List</h1>
            <button className="btn btn-primary" onClick={() => navigate('/manager/giftvoucher')}>Add Gift Voucher</button>
        <div class="row container">                
            <div className='row'>
                <Col className="col-md-6">
                    <Form.Label>VSO</Form.Label>
                    <Form.Select
                    id="vso"
                    name="vso"
                    value={selectedStaffId}
                    onChange={handleStaffChange}
                    >
                        <option value="">Select VSO</option>
                        {Staffs.map((staff)=>(
                            <option key={staff.id} value={staff.id}>
                                {staff.fullname}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
                <Col className="col-md-6">
                    <Form.Label>Client/Doctor</Form.Label>
                    <Form.Select
                    id="client"
                    name="client"
                    value={selectedClientId}
                    onChange={handleClientChange}
                    >
                        <option value="">Select Client/Doctor</option>
                        {Clients.length > 0 ?(
                        Clients.map((client)=>(
                            <option key={client.client_id} value={client.client_id}>
                                {client.client_name}
                            </option>
                        ))
                        ):(null)}
                    </Form.Select>
                </Col>
            </div>
            <div className='row'>
            <Col className="col-md-6">
                    <Form.Label>From Date</Form.Label>
                    <Form.Control
                        type="date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                    />
                </Col>
                <Col className="col-md-6">
                    <Form.Label>To Date</Form.Label>
                    <Form.Control
                        type="date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                    />
                </Col>
            </div>
        <div className="overflow-auto" style={{ maxHeight: '500px' }}>
          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>VNo</th>
                <th>Date</th>
                <th>Particulars</th>
                <th>Value</th>
                <th>Points</th>                
                <th></th>
              </tr>
            </thead>
            <tbody>
                {FilteredVouchers.length>0?(FilteredVouchers.map((voucher) => (
                <tr key={voucher.vid}>
                    <td>{voucher.vid}</td>
                    <td>{voucher.vdate}</td>
                    <td>{voucher.particulars}</td>
                    <td>{voucher.vvalue}</td>
                    <td>{voucher.vpoints}</td>                    
                    <td>
                        <button className="btn btn-primary" onClick={() => handleViewDetails(voucher.vid)}>View Details</button>
                    </td>
                </tr>
                ))):(null)}
            </tbody>
            </Table>
            </div>
        </div>        
        </Container>
        
    );
}
export default GiftVoucherList;