import React, { useEffect, useState } from "react";
import {
  Table,
  Container,
  Row,
  Col,
  Modal,
  Button,
  Card,
  Form
} from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import AddClient from "./Forms/AddClient";
import EditClient from "./Forms/EditClient";

const Clients = () => {
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState("Add");
  const [data, setData] = useState([]);
  const [item, setItem] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const navigate = useNavigate();

  const printedBy = localStorage.getItem("userName") || "Admin"; // Replace with actual logic if needed
  const currentDateTime = new Date();
  const printedOn = currentDateTime.toLocaleString();


  // Function to handle opening the modal for adding a new Client
  const handleOpen = () => {
    navigate("/master/clients/add");
  };

  // Function to handle opening the modal for editing a client
  const handleEdit = (item) => {
    setType("Edit");
    setItem(item);
    setVisible(true);
  };

  // Function to handle deleting a client
  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this Client?')) {
      try {
        const response = await fetch(`/api/deleteClient/${id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          
          },
        });
  
        if (response.ok) {
          alert('Client deleted successfully!');
          fetchData(); // Refresh data after deletion
        } else {
          const errorData = await response.json();
          console.error('Error deleting Client:', errorData);
          alert(`Can't delete Client: ${errorData.error || 'Unknown error'}`);
        }
      } catch (error) {
        console.error('Error while calling the delete API:', error);
        alert('A network error occurred while deleting the Client.');
      }
    }
  };

  const onClose = () => {
    setType("Add");
    setVisible(false);
  };

  // Fetch Client data from the backend
  const fetchData = async () => {
    try {
      const response = await fetch("/api/clients");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const dataReceived = await response.json();
      console.log(dataReceived); // Debugging: Ensure the data is received correctly
      setData(dataReceived);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

    // Pagination Logic
    const totalPages = Math.ceil(data.length / itemsPerPage);
    const paginatedData = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  
    const handlePreviousPage = () => {
      if (currentPage > 1) setCurrentPage(currentPage - 1);
    };
  
    const handleNextPage = () => {
      if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };
  
    const handleItemsPerPageChange = (e) => {
      const value = parseInt(e.target.value, 10);
      if (!isNaN(value) && value > 0) {
        setItemsPerPage(value);
        setCurrentPage(1);
      }
    };
  
    // Function to export table data to PDF
    const exportPDF = () => {
      const doc = new jsPDF();
    
      // Title for the PDF
      doc.text("Client Records", 20, 10);
    
      // Define columns for the table
      const columns = ["Id", "Type", "Code", "Name", "Email", "Mobile", "Address"];
    
      // Map the data into rows for the PDF
      const rows = data.map((item, index) => [
        index + 1,
        item.client_type || "N/A",
        item.client_code || "N/A",
        item.client_name || "N/A",
        item.client_email || "N/A",
        item.client_mobile || "N/A",
        item.client_address || "N/A",
      ]);
    
      // Set the number of rows per page
      const itemsPerPage = 20;
    
      // Function to generate a single page
      const generatePage = (pageRows, startIndex, endIndex) => {
        const pageData = pageRows.slice(startIndex, endIndex);
    
        doc.autoTable({
          head: [columns],
          body: pageData,
          startY: 20,
          didDrawPage: (data) => {
            const pageHeight = doc.internal.pageSize.height;
    
            // Footer on each page
            doc.setFontSize(10);
            doc.text(`Printed By: ${printedBy}`, 20, pageHeight - 20);
            doc.text(`Printed On: ${printedOn}`, 20, pageHeight - 10);
    
            // Page number
            const pageNumber = doc.internal.getNumberOfPages();
            doc.text(`Page ${pageNumber}`, doc.internal.pageSize.width - 40, pageHeight - 10);
          },
        });
      };
    
      // Loop to generate pages
      for (let i = 0; i < rows.length; i += itemsPerPage) {
        if (i !== 0) doc.addPage();
        generatePage(rows, i, i + itemsPerPage);
      }
    
      // Save the PDF
      doc.save("client_records.pdf");
    };


  // Table columns
  const columns = [
    "Id",
    "Type",
    "Code",
    "Name",
    "Email",
    "Mobile",
    "Address",
    // "Image",
    "Action"
  ];

  // Mapping data to table rows
  const finalData = Array.isArray(data)
    ? data.map((item, index) => {
        return {
          Id: item.id ||index+1 ,
          Type: item.client_type || "N/A",
          Code: item.client_code || "N/A",
          Name: item.client_name || "N/A",
          Email: item.client_email || "N/A",
          Mobile: item.client_mobile || "N/A",
          Address: item.client_address || "N/A",
          // Image: item.client_image || "No Image",
          Action: (
            <div style={{ display: "flex", gap: "1rem" }}>
              <Button variant="primary" onClick={() => handleEdit(item)}>
                Edit
              </Button>
              <Button variant="danger" onClick={() => handleDelete(item.client_id)}>
                Delete
              </Button>
            </div>
           ),
        };
      })
    : [];

  return (
    <Container className="p-4">
      <Card
        className="mb-4 bg-light"
        style={{ boxShadow: "4px 4px 10px black " }}
      >
        <Card.Header className="bg-primary text-light">
          <h5 className="mb-0">CLIENT</h5>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Button
                style={{ backgroundColor: "#00bcd4", color: "white" }}
                onClick={handleOpen}
                className="mb-3 bg-success"
                size="vsm">
                Add Client
              </Button>
              <Button
                style={{ backgroundColor: "#00bcd4", color: "white" }}
                onClick={exportPDF}
                className="mb-3 bg-info"
                size="vsm"
              >
                Print
              </Button>
            </Col>
          </Row>
          <Row className="mb-3 justify-content-end">
            <Col xs="auto">
              <Form.Group controlId="rowsPerPage">
                <Form.Label>Rows Per Page:</Form.Label>
                <Form.Control
                  as="select"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>


          <Table striped bordered hover responsive variant="light">
            <thead style={{ backgroundColor: "black", color: "white" }}>
              <tr>
                {columns.map((col, index) => (
                  <th
                    key={index}
                    className="text-center bg-black  h6 font-weight-bold py-1"
                    style={{
                      backgroundColor: "#00bcd4",
                      color: "white",
                      width:
                        index === 0 ? "50px" : index === 1 ? "100px" : "150px",
                    }}
                  >
                    {col}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "#17a1b0", color: "black" }}>
              {finalData.length > 0 ? (
                finalData.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#cc7a00" : "#ffcc80",
                    }}
                  >
                    <td><b>{item.Id}</b></td>
                    <td>{item.Type}</td>
                    <td>{item.Code}</td>
                    <td>{item.Name}</td>
                    <td>{item.Email}</td>
                    <td>{item.Mobile}</td>
                    <td>{item.Address}</td>
                    {/* <td>
                      {item.Image !== "No Image" ? (
                        <img src={item.Image} alt="Client" width={50} />
                      ) : (
                        "No Image"
                      )}
                    </td> */}
                     <td>{item.Action}</td> 
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={columns.length} className="text-center">
                    No Client Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <Row className="mt-3">
            <Col className="text-center">
              <Button variant="secondary" onClick={handlePreviousPage} disabled={currentPage === 1}>
                Previous
              </Button>
              <span className="mx-3">
                Page {currentPage} of {totalPages}
              </span>
              <Button variant="secondary" onClick={handleNextPage} disabled={currentPage === totalPages}>
                Next
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Modal show={visible} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {type === "Add" ? "Add Product" : "Edit Product"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {type === "Add" ? (
            <EditClient client={item} close={onClose} fetchData={fetchData} />
          ) : (
            <EditClient client={item} close={onClose} fetchData={fetchData} /> // Replace with the Edit form when available
          )}
        </Modal.Body>
      </Modal>

      {/* Modal for Add/Edit Client */}
      {/* <Modal show={visible} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {type === "Add" ? "Add Client" : "Edit Client"}
          </Modal.Title>
        {/* </Modal.Header> */}
        {/* <Modal.Body>
          {type === "Add" ? (
            <AddClient onClose={onClose} />
          ) : (
            <p>Form for Editing Client</p> // Replace this with your EditClient form
          )}
        </Modal.Body>
      </Modal> */}
    </Container> 
  );
};

export default Clients;
